
        import V from 'voUtils/V.js';
        import Vue from 'vue';
        import {voMsg,voMsgPl} from './voUtils/voI18n.js';
        import DateEx from './voUtils/DateEx.js';

        Vue.prototype.VOFFICE = window.VOFFICE;

        var voFormatDate = DateEx.formatDate;

        import AirbnbStyleDatepicker from 'base/vue-airbnb-style-datepicker'
        import 'base/vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'

        var vueApps = {};

        var methods = {voMsg,voMsgPl,voFormatDate};

        
            import App0 from './app/dummy.js';
            vueApps['app/dummy.js'] = App0;
        

            import App1 from './app/search.js';
            vueApps['app/search.js'] = App1;
        

            import App2 from './app/unit.js';
            vueApps['app/unit.js'] = App2;
        

            import App3 from './app/navbar.js';
            vueApps['app/navbar.js'] = App3;
        

            import App4 from './newUnits.js';
            vueApps['newUnits.js'] = App4;
        

            import App5 from './newUnitsMob.js';
            vueApps['newUnitsMob.js'] = App5;
        

            import App6 from './unitsOfTheDay1.js';
            vueApps['unitsOfTheDay1.js'] = App6;
        

            import App7 from './unitsOfTheDayMob1.js';
            vueApps['unitsOfTheDayMob1.js'] = App7;
        

            import App8 from './recommendedUnits.js';
            vueApps['recommendedUnits.js'] = App8;
        

            import App9 from './recommendedUnitsMob.js';
            vueApps['recommendedUnitsMob.js'] = App9;
        

            import App10 from './app/SimilarUnits.js';
            vueApps['app/SimilarUnits.js'] = App10;
        

            import App11 from './app/vacation-ideas.js';
            vueApps['app/vacation-ideas.js'] = App11;
        

            import App12 from './app/vacation-family.js';
            vueApps['app/vacation-family.js'] = App12;
        

            import App13 from './app/vacation-two.js';
            vueApps['app/vacation-two.js'] = App13;
        

            import App14 from './app/vacation-hund.js';
            vueApps['app/vacation-hund.js'] = App14;
        

            import App15 from './app/vacation-friends.js';
            vueApps['app/vacation-friends.js'] = App15;
        

            import App16 from './app/vacation-special.js';
            vueApps['app/vacation-special.js'] = App16;
        

        
                import Comp17 from './carousel.vue';
                Vue.component('owl-carousel',Comp17);
            

                import Comp18 from './comp/PopupBox.vue';
                Vue.component('voffice-popup-box',Comp18);
            

                import Comp19 from './comp/DateRangeExt.vue';
                Vue.component('voffice-daterange-ext',Comp19);
            

                import Comp20 from './comp/GuestBox.vue';
                Vue.component('voffice-guestbox',Comp20);
            

                import Comp21 from './comp/ImgFallback.vue';
                Vue.component('img-fallback',Comp21);
            

                import Comp22 from './comp/PhotoSwipe.js';
                Vue.component('voffice-photoswipe',Comp22);
            

                import Comp23 from './comp/FavElement.vue';
                Vue.component('voffice-fav-element',Comp23);
            

                import Comp24 from './comp/MaxTextSize.js';
                Vue.component('voffice-max-text-size',Comp24);
            

                import Comp25 from './comp/numberformat.vue';
                Vue.component('number-value',Comp25);
            

                import Comp26 from './comp/ContactForm.js';
                Vue.component('voffice-form',Comp26);
            

                import Comp27 from './comp/DocBox.vue';
                Vue.component('voffice-doc-box',Comp27);
            

                import Comp28 from './comp/ErrorMessage.vue';
                Vue.component('voffice-error-message',Comp28);
            

                import Comp29 from './comp/Booking.js';
                Vue.component('voffice-booking',Comp29);
            

                import Comp30 from './comp/openstreetmap.vue';
                Vue.component('voffice-openstreetmap',Comp30);
            

                import Comp31 from './comp/gmap.js';
                Vue.component('voffice-gmap',Comp31);
            

                import Comp32 from './pricehelp.vue';
                Vue.component('price-help',Comp32);
            

                import Comp33 from './comp/stardisplay.vue';
                Vue.component('star-display',Comp33);
            

                import Comp34 from './newsltterSubscription.js';
                Vue.component('voffice-newsletter-subscription',Comp34);
            

                import Comp35 from './comp/DateRange.vue';
                Vue.component('voffice-daterange',Comp35);
            

                import Comp36 from './comp/CookieBox.vue';
                Vue.component('voffice-cookie-box',Comp36);
            

                import Comp37 from './comp/SearchFilterTokens.vue';
                Vue.component('search-filter-tokens',Comp37);
            

                import Comp38 from './ImageCarousel.vue';
                Vue.component('img-carousel',Comp38);
            

                import Comp39 from './comp/Paginate.vue';
                Vue.component('voffice-paginate',Comp39);
            

                import Comp40 from './comp/PopupDialog.vue';
                Vue.component('voffice-popup-dlg',Comp40);
            

                import Comp41 from './comp/Voucher.vue';
                Vue.component('voffice-voucher',Comp41);
            

        Vue.mixin({
            methods: methods
        });

        const datepickerOptions = {
            days: voMsg('weekdayslist').split(','),
            daysShort: voMsg('weekdayslist.short').split(','),
            monthNames: voMsg('monthlist.full').split(',')
        };
        Vue.use(AirbnbStyleDatepicker, datepickerOptions);


    	var passThrough = function (val) {
			return val || '';
		};

    	var filterCodes = [
    	    'vlang',
    	    'vautolang',
    	    'vsoption',
    	    'vsPropOption'
    	  ];

        for (let fc of filterCodes) {
    		Vue.filter(fc, passThrough);
    	};


        var vueAppEls = document.body.querySelectorAll('[vo-vue-app]');

        for (let el of vueAppEls) {
            let vName = el.getAttribute("vo-vue-app");
            let v = vueApps[vName];
            if (v) {
                v.el = el;
                V.log("creating vue app",vName);

                let app = new Vue(v);
            }

        }

        VOFFICE.afterAllLoaded = function(){
            document.documentElement.classList.add("app-loaded");
        };

        window.addEventListener('beforeunload', function (event) {
        	document.documentElement.classList.add("app-leaving");
        });


    